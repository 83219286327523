import { ShippingType } from "./types"
export const shippingResponsibilities = [
  {title:ShippingType.Customer,label:"Customer", key:0},
  {title:ShippingType.Thumbwar,label:"THUMBWAR", key:1},
  {title:ShippingType.Other,label:"Other", key:2},
  {title:ShippingType.None,label:"None", key:3}
]
export function getShippingResponsibility(shippingString) {
  switch(shippingString) {
    case ShippingType.Customer: 
      return 0;
    case ShippingType.Thumbwar: 
      return 1;
    case ShippingType.Other: 
      return 2;
    case ShippingType.None: 
      return 3;
  }
}